import React, { useEffect, useMemo } from "react";
import {
  IconButton,
  InputLabel,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import TextEditorCustomToolbar from "../../../components/TextEditorCustomToolbar";
import AutocompleteFilter from "../../../components/filters_new/AutocompleteFilter";
import {
  IAutomationAction,
  ISelectedActionComponent,
  ISendEmailFormField,
} from "../../../models/Automation";
import {
  getSendEmailFormFields,
  sendEmailFormFieldsDefaultValue,
} from "./config";
import { getAutomationState } from "../../../store_new/selectors/Automation";
import {
  fetchSelectedActionData,
  setActions,
  setSelectedAction,
} from "../../../store_new/reducers/Automation";
import { TListOption } from "../../../models/common";
import { getCurrentUserData } from "../../../store_new/selectors/CurrentUser";
import { StyledSendMailFormContainer } from "../style";

const SendEmail = ({
  t,
  selectedAction,
  onChangeActiveTrigger,
}: ISelectedActionComponent) => {
  const dispatch = useDispatch();

  const { firstName, lastName, username } = useSelector(getCurrentUserData);

  const { selectedActionData, currentAutomation } =
    useSelector(getAutomationState);
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    getValues,
  } = useForm({
    mode: "onSubmit",
    defaultValues: sendEmailFormFieldsDefaultValue,
    resolver: yupResolver(getSendEmailFormFields(t)),
  });

  const errorsOrCC =
    Object.keys(errors).length > 0 || selectedAction?.params.isCCorBCC;
  const smallScreenHeight = window.innerHeight < 704;
  const maximumFormHeight = errorsOrCC && smallScreenHeight ? 340 : "auto";

  const formattedMembers = useMemo(() => {
    if (selectedActionData?.members) {
      const newMembers = Object.entries(selectedActionData.members).map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([value, label]) => ({
          value: label,
          label,
        })
      );
      return newMembers;
    }
    return [];
  }, [selectedActionData]);

  const formattedVariables = useMemo(() => {
    if (currentAutomation.trigger) {
      const variables = Object.entries(currentAutomation.trigger.params).map(
        ([key]) => ({
          value: key,
          label: t(`automation.variables.${key}`),
        })
      );
      return variables;
    }

    return [];
  }, [currentAutomation.trigger?.params]);

  const onClose = () => {
    onChangeActiveTrigger(undefined);
  };

  const handleChangeCCorBcc = (CCOrBCC: boolean) => {
    const updatedActions = currentAutomation.actions.map(
      (action: IAutomationAction) => {
        if (action.id === selectedAction?.id) {
          const formattedSelectedAction = {
            ...selectedAction,
            params: {
              ...selectedAction?.params,
              to: getValues().to,
              isCCorBCC: CCOrBCC ?? false,
            },
          };
          dispatch(
            setSelectedAction(formattedSelectedAction as IAutomationAction)
          );
          return {
            ...action,
            params: {
              ...action.params,
              isCCorBCC: CCOrBCC ?? false,
            },
          };
        }
        return action;
      }
    );
    dispatch(setActions(updatedActions as IAutomationAction[]));
  };

  const onSubmit = (data: ISendEmailFormField) => {
    const updatedActions = currentAutomation.actions.map(
      (action: IAutomationAction) => {
        if (action.id === selectedAction?.id) {
          return {
            ...selectedAction,
            actionCompleted: true,
            params: {
              ...data,
              sender_name: `${firstName} ${lastName}`,
              sender_email: username,
              cc: data.cc.length > 0 ? data.cc : undefined,
              bcc: data.bcc.length > 0 ? data.bcc : undefined,
            },
          };
        }
        return action;
      }
    );
    dispatch(setActions(updatedActions as IAutomationAction[]));
    dispatch(setSelectedAction(undefined));
  };

  useEffect(() => {
    if (!selectedActionData?.members) {
      dispatch(fetchSelectedActionData("members"));
    }
  }, []);

  useEffect(() => {
    setValue("to", selectedAction?.params.to as string);
    if (selectedAction?.params.subject !== "string") {
      setValue("subject", selectedAction?.params.subject as string);
    }
    if (selectedAction?.params.message !== "string") {
      setValue("message", selectedAction?.params.message as string);
    }
    if (selectedAction?.params?.isCCorBCC) {
      setValue("cc", selectedAction?.params.cc as string);
      setValue("bcc", selectedAction?.params.bcc as string);
    } else if (selectedAction?.params.cc || selectedAction?.params.bcc) {
      handleChangeCCorBcc(true);
      setValue("cc", selectedAction?.params.cc as string);
      setValue("bcc", selectedAction?.params.bcc as string);
    }
  }, [selectedAction]);

  return (
    <Stack
      width="100%"
      padding="0px 16px 16px 16px"
      borderRadius={1.5}
      sx={{ background: "#fff" }}
    >
      <Stack
        mt="0px !important"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontSize={16} fontWeight={500}>
          {t("automation.sendEmailTo")}
        </Typography>
        <Stack height="100%" alignItems="center">
          <IconButton onClick={onClose}>
            <XMarkIcon stroke="#111827" width={20} height={20} />
          </IconButton>
        </Stack>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSendMailFormContainer maxHeight={maximumFormHeight} spacing={1}>
          <Stack>
            <InputLabel className="required-label">
              {t("automation.to")}
            </InputLabel>
            <Controller
              name={"to"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutocompleteFilter
                  onChange={onChange}
                  value={value}
                  options={formattedMembers as unknown as TListOption[]}
                  placeholder={t("automation.emailPlaceholder")}
                  errors={errors["to"]}
                />
              )}
            />
          </Stack>
          {selectedAction?.params?.isCCorBCC ? (
            <Stack
              mt={errors?.to?.message ? "16px !important" : "8px !important"}
              spacing={1}
            >
              <Stack>
                <InputLabel>CC</InputLabel>
                <Controller
                  name={"cc"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutocompleteFilter
                      onChange={onChange}
                      value={value}
                      options={formattedMembers as unknown as TListOption[]}
                      placeholder={t("automation.emailPlaceholder")}
                    />
                  )}
                />
              </Stack>
              <Stack>
                <InputLabel>BCC</InputLabel>
                <Controller
                  name={"bcc"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutocompleteFilter
                      onChange={onChange}
                      value={value}
                      options={formattedMembers as unknown as TListOption[]}
                      placeholder={t("automation.emailPlaceholder")}
                    />
                  )}
                />
              </Stack>
            </Stack>
          ) : (
            <Stack
              direction="row"
              mt={errors?.to?.message ? "16px !important" : "8px !important"}
              spacing={1}
            >
              <Typography
                className="cc-bcc"
                onClick={() => handleChangeCCorBcc(true)}
              >
                CC
              </Typography>
              <Typography
                className="cc-bcc"
                onClick={() => handleChangeCCorBcc(true)}
              >
                BCC
              </Typography>
            </Stack>
          )}
          <Stack
            mt={
              selectedAction?.params?.isCCorBCC
                ? "8px !important"
                : "4px !important"
            }
            spacing={0.25}
          >
            <InputLabel className="required-label">
              {t("automation.subject")}
            </InputLabel>

            <Controller
              name={"subject"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutocompleteFilter
                  onChange={onChange}
                  value={value}
                  options={formattedVariables as unknown as TListOption[]}
                  placeholder={t("automation.subjectPlaceholder")}
                  errors={errors.subject}
                />
              )}
            />
          </Stack>
          <Stack spacing={0.25}>
            <InputLabel className="required-label">
              {t("automation.content")}
            </InputLabel>
            <Controller
              name="message"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextEditorCustomToolbar
                  items={formattedVariables}
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.message?.message as string}
                />
              )}
            />
          </Stack>
        </StyledSendMailFormContainer>
        <Stack
          mt={2}
          direction="row"
          width="100%"
          justifyContent="flex-end"
          spacing={1.5}
        >
          <Button variant="outlined" onClick={onClose}>
            {t("button.secondCancel")}
          </Button>
          <Button variant="contained" type="submit">
            {t("button.save")}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default SendEmail;

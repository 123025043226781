import * as yup from "yup";
import { TFunction } from "i18next";
import { ISendEmailFormField } from "../../../models/Automation";

export const getSendEmailFormFields = (t: TFunction) =>
  yup.object().shape({
    to: yup
      .string()
      .required(t("register.message") as string)
      .email(t("invalid_email_message") as string),
    subject: yup.string().required(t("register.message") as string),
    message: yup.string().required(t("register.message") as string),
    cc: yup.string(),
    bcc: yup.string(),
  });

export const sendEmailFormFieldsDefaultValue: ISendEmailFormField = {
  to: "",
  subject: "",
  message: "",
  cc: "",
  bcc: "",
};
